body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(bg.jpeg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  height: 100vh;
  margin: 0
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace
}

.cursor-pointer {
  cursor: pointer
}

.player-wrapper {
  padding-top: 56.25%;
  position: relative
}

.react-player {
  left: 0;
  position: absolute;
  top: 0
}

.player-wrapper2 {
  padding-top: 56.25%;
  position: relative
}

@media (max-width:991.98px) {
  .player-wrapper2 {
    padding-top: 100%
  }
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #a0a0a0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #808080; 
}